import "./footer.scss";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content container">
        <div className="footer__content__logo">
          <div className="logo">
            <img src={Logo} alt="" />
            <Link to="/">uhdMovies</Link>
          </div>
        </div>
        <div className="footer__content__menus">
          <div className="footer__content__menu">
            <a href="https://kingori.co.ke" target="">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
